@import "colors";

.sharePopper {

    .popper {
        z-index: 999999999;
        width: 600px;
        padding: 22px; } }

.row {
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px; }

.shareIcon {
    width: 40px;
    height: 40px;
    border-radius: 28px;
    background-color: $sideNav-dk-orange;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    margin-right: 16px; }

.shareIcon  img {
    width: 100%;
    height: 100%; }

.shareText {
    margin-bottom: 15px;
    display: flex;
    align-items: center; }

.shareText p {
    margin: 0;
    font-size: 22px;
    font-family: "Mulish", sans-serif;
    line-height: 32px;
    font-weight: 100;
    color: $sharePopper-dk-grey; }

.groupsText {
    font-family: "Mulish", sans-serif;
    color: $sharePopper-dk-grey;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 12px; }

.searchBar input {
    width: 100%;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 12px;
    background-color: #F5F8FF;
    margin-bottom: 12px; }

.searchBar input::placeholder {
    font-family: "Mulish", sans-serif;
    letter-spacing: 0.18px;
    color: #878991;
    font-size: 18px;
    line-height: 23px;
    font-weight: 100; }

.searchBar input:focus-visible {
    border: 1px solid #F66322;
    outline: #F66322; }


.toggleRow {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    display: flex; }

.organizationName {
    font-family: "Mulish", sans-serif;
    color: $sharePopper-dk-grey;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.18px;
    margin-left: 15px;
    width: 70%; }


.organizationDataWrap {
    height: 140px;
    overflow: auto; }


.organizationData {
    margin: 0 40px 0 26px; }

.personDetailWrap {
    height: 220px;
    overflow-y: auto; }

.personWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 40px 12px 26px; }

.personDetail {
    margin-left: 16px; }

.personEmail {
    letter-spacing: 0.18px;
    color: #878991;
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    line-height: 23px;
    font-weight : lighter; }

.personName {
    font-family: "Mulish", sans-serif;
    color: $sharePopper-dk-grey;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
    letter-spacing: 0.18px; }

.personDp {
    display: flex;
    align-items: center; }


.checkbox {
    display: flex;
    align-items: center;
    margin-left: 26px;
    margin-top: 12px; }

.checkbox span {
    letter-spacing: 0.18px;
    color: $sharePopper-dk-grey;
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    line-height: 23px;
    font-weight : lighter; }

.checkbox input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer; }

.cross :hover {
    cursor: pointer;
    background  : #E0E5EB;
    stroke: #fff;
    padding: 6px;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%; }

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 2.5em;

  input {
    opacity: 0;
    width: 0;
    height: 0; } }


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 30px;
  width: 53px;
  height: 26px;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    border-radius: 20px;
    left: 2px;
    bottom: 4px;
    background-color: white;
    transition: .4s; } }

input {
  &:checked + .slider {
    background-color: #F66322; }

  &:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }

  &:checked + .slider:before {
    transform: translateX(1.6em); } }


//scrolbar

 ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent; }

// Track /

 ::-webkit-scrollbar-track {
  border-radius: 16px; }

// Handle /

 ::-webkit-scrollbar-thumb {
  background: #595959;
  border-radius: 16px; }

.sharePopperButtonsRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 10px;
    height: 50px; }

.sharePopperCancelBtn {
    border: 1px solid #F66322;
    border-radius: 9px;
    padding: 0px 0px;
    background: $white;
    color: #F66322;
    width: 100px;
    height: 40px;
    margin-right: 15px; }

.sharePopperDoneBtn {
    border: 1px solid #F66322;
    border-radius: 9px;
    padding: 0px 0px;
    height: 40px;
    width: 100px; }
